import env from "env-sanitize"

const config = {
  isStaging: env("STAGING", x => x.asBoolean(), false),
  azure: {
    apimGatewayUrl: process.env.GATSBY_AZURE_APIM_GATEWAY_URL ?? "",
    apimSubscriptionKey: process.env.GATSBY_AZURE_APIM_SUBSCRIPTION_KEY ?? "",
  },
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID ?? "",
    dataset: process.env.GATSBY_SANITY_DATASET ?? "",
  },
}

export default config
