import * as React from "react"

import { getAzureApiManagementClient } from "../../api/clients"
import { SendEmailApi, VerifyReCaptchaApi } from "../../api/services"

type ApiProviderProps = { children: React.ReactNode }
interface ApiContextInterface {
  sendEmailApi: SendEmailApi
  verifyReCaptchaApi: VerifyReCaptchaApi
}
const ApiContext = React.createContext<ApiContextInterface | null>(null)

const ApiProvider = ({ children }: ApiProviderProps) => {
  const azureApimClient = getAzureApiManagementClient()
  const sendEmailApi = new SendEmailApi(azureApimClient)
  const verifyReCaptchaApi = new VerifyReCaptchaApi(azureApimClient)
  const value = { sendEmailApi, verifyReCaptchaApi }
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export { ApiProvider, ApiContext }
