import axios, { AxiosRequestConfig } from "axios"
import cfg from "../core/utils/config"

const createClient = (config: AxiosRequestConfig) => axios.create(config)

export const getAzureApiManagementClient = () => {
  const config = {
    baseURL: cfg.azure.apimGatewayUrl,
    headers: {
      "Ocp-Apim-Subscription-Key": cfg.azure.apimSubscriptionKey,
      "Content-Type": "application/json",
    },
  }

  return createClient(config)
}
