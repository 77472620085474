import * as React from "react"
import { GatsbyBrowser } from "gatsby"
import { TagManagerContextProvider } from "../core/contexts/tagManager"
import { ApiProvider } from "../core/contexts/api"

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <TagManagerContextProvider>
      <ApiProvider>{element}</ApiProvider>
    </TagManagerContextProvider>
  )
}

export default wrapRootElement
