import { AxiosInstance } from "axios"

export type SendEmailData = {
  from: string
  to: string
  name: string
  subject: string
  company?: string
  message: string
}

export class SendEmailApi {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  execute = (data: SendEmailData) => this.client.post("/email/send", data)
}
