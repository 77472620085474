import * as React from "react"
import TagManager from "../../services/tagManager"

export const TagManagerContext = React.createContext<TagManager | null>(null)

type TagManagerContextProviderProps = {
  children: React.ReactNode
}

export const TagManagerContextProvider = ({
  children,
}: TagManagerContextProviderProps) => {
  const tagManager = new TagManager()
  return (
    <TagManagerContext.Provider value={tagManager}>
      {children}
    </TagManagerContext.Provider>
  )
}
